import {APP_INSTALLED_ACTION, APP_REMOVED_ACTION, ECOM_APP_DEF_ID} from './constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponent, withEcomPlatform} from '@wix/ecom-platform-sdk/dist/es/src';
import {doTransaction} from '../editor-script/transaction';
import {SPECS} from '../editor-script/constants';
import Experiments from '@wix/wix-experiments';
import {Experiments as StoresExperiments} from '../common/experiments/Experiments';

const ecomComponents = [EcomComponent.CART_ICON, EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
let sdk: IEditorSdk;
let storesExperiments: StoresExperiments;

async function getEcomPagesData() {
  const ecomApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === ecomApplicationId);
}

async function checkIfPageExists(page: PageMap) {
  const ecomPagesData = await getEcomPagesData();
  return !!ecomPagesData.find((p) => p.tpaPageId === page);
}

function addStoresPages() {
  return doTransaction(
    sdk,
    async () => {
      if (await checkIfPageExists(PageMap.PRODUCT)) {
        return Promise.resolve();
      }

      const ecomPublicApi = sdk.application.getPublicAPI('', {appDefinitionId: ECOM_APP_DEF_ID});
      const productPagePromise = ecomPublicApi.addPage(PageMap.PRODUCT);
      const shopPagePromise = ecomPublicApi.addPage(PageMap.GALLERY, true);

      return Promise.all([shopPagePromise, productPagePromise]);
    },
    storesExperiments
  );
}

async function editorReady(editorSDK, _appDefId, options) {
  sdk = editorSDK;

  const isEcomPlatformInstalled = await sdk.document.tpa.isApplicationInstalled('', {
    appDefinitionId: ECOM_APP_DEF_ID,
  });

  const experiments = new Experiments();
  await experiments.conduct(SPECS.SupportConcurrentEditing, 'false');
  await experiments.conduct(SPECS.InstallNewStoresUponStoresInstallation, 'false');
  storesExperiments = new StoresExperiments({
    [SPECS.SupportConcurrentEditing]: experiments.enabled(SPECS.SupportConcurrentEditing) ? 'true' : 'false',
    [SPECS.InstallNewStoresUponStoresInstallation]: experiments.enabled(SPECS.InstallNewStoresUponStoresInstallation)
      ? 'true'
      : 'false',
  });

  if (options.firstInstall && isEcomPlatformInstalled) {
    await addStoresPages();
  }
}

async function handleAction({type, payload}) {
  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === ECOM_APP_DEF_ID) {
    await addStoresPages();
  }

  if (
    type === APP_REMOVED_ACTION &&
    payload?.appDefinitionId === ECOM_APP_DEF_ID &&
    storesExperiments.enabled(SPECS.InstallNewStoresUponStoresInstallation)
  ) {
    await sdk.tpa.app.delete();
    await sdk.document.save();
  }
}

const editorScript = {
  editorReady,
  handleAction,
};

export const storesEditorScript = withEcomPlatform(editorScript, ecomComponents);
